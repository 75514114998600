import React, { useState, useEffect, ChangeEvent, useCallback, Dispatch, useMemo } from 'react'
import isNumber from 'lodash/isNumber'
import { BigNumber } from 'bignumber.js'
import { InputAdornment, OutlinedInput, TextField, styled } from '@mui/material'
import { toHankakuNumber } from 'utils/zenkaku'
import { DigitsUnits, DigitsUnitType } from 'types/user'
import { currency as t } from 'public/locales/ja/components/common/currency'
import { EMPTY_STRING } from 'utils/string'
import { numberRegex } from 'utils/number'

export const digitsUnitsMapsLabel = {
  [DigitsUnits.Thousand]: t.thousand,
  [DigitsUnits.TenThousand]: t.tenThousand,
  [DigitsUnits.OneMillion]: t.oneMillion,
  [DigitsUnits.OneHundredMillion]: t.oneHundredMillion,
} as const

interface CurrencyTextProps {
  value?: number | BigNumber | '' | null
  digits: DigitsUnitType
  isShowDigitsUnit?: boolean
  isShowCurrency?: boolean
}
export const CurrencyText: React.FC<React.PropsWithChildren<CurrencyTextProps>> = ({
  value,
  digits,
  isShowDigitsUnit = true,
  isShowCurrency = false,
}) => {
  if (isNumber(value) && isShowDigitsUnit) {
    return (
      <>
        {isShowCurrency ? '¥' : ''}
        {(value / 10 ** digits).toLocaleString() + digitsUnitsMapsLabel[digits]}
      </>
    )
  }

  if (isNumber(value) && !isShowDigitsUnit) {
    return (
      <>
        {isShowCurrency ? '¥' : ''}
        {(value / 10 ** digits).toLocaleString()}
      </>
    )
  }

  return <>{EMPTY_STRING}</>
}

interface CurrencyInputProps extends CurrencyTextProps {
  onChange: (value: number | BigNumber | '') => void
  onInput?: (value: number | BigNumber | '') => void
  required?: boolean
  onValidation?: (isError: boolean) => void
  error?: string
  isStandard?: boolean
  disabled?: boolean
  bgWhite?: boolean
  isSlim?: boolean
}

export function useCurrencyProps(
  init: number | '',
  digits: DigitsUnitType,
  required?: boolean,
  label?: string
): [props: CurrencyInputProps, error: string, setValue: Dispatch<React.SetStateAction<number | '' | BigNumber>>] {
  const [value, setValue] = useState<number | BigNumber | ''>(init)
  const [error, setError] = useState('')
  const onChange = useCallback((val: number | BigNumber | '') => setValue(val), [])

  const isOverSafeInteger = useMemo(() => {
    if (!value) return false
    if (BigNumber.isBigNumber(value)) {
      return value.gt(Number.MAX_SAFE_INTEGER)
    }
    return Number(value) > Number.MAX_SAFE_INTEGER
  }, [value])

  const onValidation = useCallback(
    (isError: boolean) => {
      if (isError) {
        if (label) {
          setError(t.validation.getRequired(label))
        } else {
          setError(t.common.input)
        }
      } else if (isOverSafeInteger) {
        setError(t.validation.number.getMax(t.validation.number.limit))
      } else {
        setError('')
      }
    },
    [label, value]
  )
  return [{ value, digits, required: !!required, onChange, onValidation }, error, setValue]
}

export function useRangeCurrencyProps(
  min: number | BigNumber | '',
  max: number | BigNumber | '',
  digits: DigitsUnitType,
  label?: string
): [CurrencyInputProps, CurrencyInputProps, string] {
  const [minValue, setMinValue] = useState<number | BigNumber | ''>(min)
  const [maxValue, setMaxValue] = useState<number | BigNumber | ''>(max)
  const [error, setError] = useState('')
  useEffect(() => {
    if (minValue === '' || maxValue === '') {
      if (label) {
        setError(t.validation.getRequired(label))
      } else {
        setError('')
      }
    }
    if (minValue !== '' && maxValue !== '') {
      if (minValue <= maxValue) {
        setError('')
      } else {
        setError(t.validation.number.range)
      }
    }
  }, [label, minValue, maxValue])
  const minProps = {
    value: minValue,
    digits,
    required: !!label,
    onChange: useCallback((val: number | BigNumber | '') => setMinValue(val), []),
  }
  const maxProps = {
    value: maxValue,
    digits,
    required: !!label,
    onChange: useCallback((val: number | BigNumber | '') => setMaxValue(val), []),
  }
  return [minProps, maxProps, error]
}

export const CurrencyInput: React.FC<React.PropsWithChildren<CurrencyInputProps>> = ({
  value,
  digits,
  onChange,
  onInput,
  required,
  onValidation,
  error,
  isStandard,
  disabled,
  bgWhite,
  isSlim,
}) => {
  const [num, setNum] = useState<number | string | undefined>(
    typeof value === 'number' ? (value / 10 ** digits).toLocaleString() : ''
  )

  useEffect(() => {
    setNum(typeof value === 'number' ? (value / 10 ** digits).toLocaleString() : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNum(e.target.value)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    onInput && onInput(Number(e.target.value))
  }

  const handleBlur = () => {
    const formattedNum = num !== '' ? toHankakuNumber(`${num}`) : ''
    const replacedValue = Number.isFinite(formattedNum) ? `${formattedNum}`.replace(numberRegex, '') : ''

    if (replacedValue === '') {
      onChange('')
      setNum('')
      if (required && onValidation) {
        onValidation(true)
      }
      return
    }

    const numValue = Number(replacedValue)
    onChange(new BigNumber(10 ** digits).times(numValue).toNumber())
    setNum(numValue)

    if (
      replacedValue === '-' ||
      replacedValue[replacedValue.length - 1] === '.' ||
      (replacedValue.includes('.') && replacedValue[replacedValue.length - 1] === '0')
    ) {
      setNum(replacedValue)
    } else if (!isNaN(numValue)) {
      setNum(numValue)
    }

    if (onValidation) {
      onValidation(false)
    }
  }

  const handleFocus = () => {
    if (typeof num === 'string') {
      setNum(num.replace(/,/g, ''))
    }
  }

  if (isStandard) {
    return (
      <TextField
        variant="standard"
        error={!!error}
        value={num?.toLocaleString()}
        onChange={handleChange}
        onInput={handleInput}
        onBlur={handleBlur}
        onFocus={handleFocus}
        required={required}
        InputProps={{
          endAdornment: <InputAdornmentStyled position="end">{digitsUnitsMapsLabel[digits]}</InputAdornmentStyled>,
        }}
        disabled={disabled}
      />
    )
  }

  return (
    <OutlinedInputStyled
      error={!!error}
      value={num?.toLocaleString()}
      onChange={handleChange}
      onInput={handleInput}
      onBlur={handleBlur}
      onFocus={handleFocus}
      required={required}
      endAdornment={<InputAdornmentStyled position="end">{digitsUnitsMapsLabel[digits]}</InputAdornmentStyled>}
      disabled={disabled}
      bgWhite={bgWhite}
      isSlim={isSlim}
    />
  )
}

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  ['p']: {
    color: theme.tertiary.lightness2,
  },
}))

const OutlinedInputStyled = styled(OutlinedInput)<{ bgWhite?: boolean; isSlim?: boolean }>(
  ({ theme, bgWhite, isSlim }) => {
    return {
      backgroundColor: bgWhite ? 'white' : theme.grey.lightness4,
      ['input:placeholder']: {
        color: theme.tertiary.lightness2,
      },
      height: isSlim ? 'inherit' : theme.spacing(6.25),
      ['fieldset']: {
        borderColor: theme.tertiary.lightness2,
      },
      width: 'fit-content',
      '& .MuiOutlinedInput-input': {
        padding: isSlim ? '8px 10px' : '16.5px 14px',
      },
    }
  }
)
